import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


//成交分析区域 数据
export function lunbolist(params) {
	return loginRequest({
    url: '/frontend/website/lunbo/list',
		method: 'post',
		data:Qs.stringify(params)
	})
}